import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { Route, Routes, Navigate } from "react-router-dom";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { ChatPage } from "./pages/chat-page";
import { ConnectPage } from "./pages/connect-page";
import { CalendarPage } from "./pages/calendar-page";


const AuthAwareRouter = () => {
    const { isAuthenticated, isLoading } = useAuth0();
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    return (
      <Routes>
        <Route 
          path="/" 
          element={isAuthenticated ? <Navigate to="/chat" replace /> : <HomePage />} 
        />
        <Route
          path="/chat"
          element={<AuthenticationGuard component={ChatPage} />}
        />
        <Route
          path="/calendar"
          element={<AuthenticationGuard component={CalendarPage} />}
        />
        <Route
          path="/profile"
          element={<AuthenticationGuard component={ProfilePage} />}
        />
        <Route
          path="/connect"
          element={<AuthenticationGuard component={ConnectPage} />}
        />
        <Route path="/callback" element={<CallbackPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  };
  
  export default AuthAwareRouter;