import React, { useEffect } from 'react';
import Linkify from 'linkify-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
} from 'recharts';

interface ChartData {
  chart_title: string;
  lines: string[] | string;
  data: { [key: string]: any }[];
}

interface ChartComponentProps {
  data: ChartData;
}

const COLORS = [
  '#43aa8b', // Soft Teal
  '#ff5722', // Bright Warm Orange
  '#f2c12e', // Golden Yellow
  '#495867', // Dusty Blue Gray
  '#b0e0e6', // Pale Powder Blue
  '#bf9b30', // Muted Mustard
  '#ecb390', // Peachy Beige
  '#7d8c7f', // Muted Sage
  '#1f4068', // Dark Slate Blue
  '#6b4226', // Warm Chestnut Brown
];

const ChartComponent: React.FC<ChartComponentProps> = ({ data }) => {
  const { chart_title, lines, data: chartData } = data;

  // Ensure lines is an array
  const lineKeys = Array.isArray(lines) ? lines : [lines];

  return (
    <>
      <h3 className="text-white">{chart_title}</h3>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={chartData}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {lineKeys.map((lineKey: string, index: number) => (
            <Line
              key={index}
              type="monotone"
              dataKey={lineKey}
              stroke={COLORS[index % COLORS.length]}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

interface PieChartData {
  chart_title: string;
  data: { name: string; value: number }[];
}

interface PieChartComponentProps {
  data: PieChartData;
}

const PieChartComponent: React.FC<PieChartComponentProps> = ({ data }) => {
  const { chart_title, data: chartData } = data;

  return (
    <>
      <h3 className="text-white">{chart_title}</h3>
      <ResponsiveContainer width="100%" height={200}>
        <PieChart>
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            outerRadius={80}
            label
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

interface BarChartDataPoint {
  name: string;
  [key: string]: number | string; // Allow both number and string types
}

interface BarChartData {
  chart_title: string;
  x_axis_label: string;
  y_axis_label: string;
  data: BarChartDataPoint[];
}

interface BarChartComponentProps {
  data: BarChartData;
}

const BarChartComponent: React.FC<BarChartComponentProps> = ({ data }) => {
  const { chart_title, x_axis_label, y_axis_label, data: chartData } = data;

  // Extract bar keys with defensive programming
  const barKeys = Array.isArray(chartData) && chartData.length > 0 && chartData[0]
    ? Object.keys(chartData[0]).filter(
        key => key !== 'name' && typeof chartData[0][key] === 'number'
      )
    : [];

  // If we have no data or no valid bar keys, show a message
  if (!Array.isArray(chartData) || chartData.length === 0 || barKeys.length === 0) {
    return (
      <>
        <h3 className="text-white">{chart_title}</h3>
        <div className="text-red-500 p-4 text-center">
          No valid chart data available
        </div>
      </>
    );
  }

  return (
    <>
      <h3 className="text-white">{chart_title}</h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData}>
          <XAxis
            dataKey="name"
            label={{ value: x_axis_label, position: 'insideBottom', dy: 10 }}
          />
          <YAxis
            label={{
              value: y_axis_label,
              angle: -90,
              position: 'insideLeft',
            }}
          />
          <Tooltip />
          <Legend />
          {barKeys.map((key, index) => (
            <Bar
              key={key}
              dataKey={key}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

// New helper function to render markdown text within cells
function renderMarkdownText(text: string): React.ReactNode[] {
  const boldRegex = /\*\*(.*?)\*\*/g;
  const parts: React.ReactNode[] = [];
  let lastIndex = 0;
  let match;

  while ((match = boldRegex.exec(text)) !== null) {
    // Add text before the bold text
    if (match.index > lastIndex) {
      parts.push(text.substring(lastIndex, match.index));
    }
    // Add the bold text
    parts.push(
      <strong key={match.index} className="font-semibold">
        {match[1]}
      </strong>
    );
    lastIndex = boldRegex.lastIndex;
  }
  // Add remaining text
  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }

  return parts;
}

// Updated function to parse markdown tables and render bold text
function parseMarkdownTable(tableText: string): React.ReactNode {
  const lines = tableText.trim().split('\n');

  // Ensure there are at least 3 lines (header, separator, at least one row)
  if (lines.length < 3) return null;

  const [headerLine, separatorLine, ...rows] = lines;

  // Split the header and separator lines
  const headers = headerLine
    .split('|')
    .map(h => h.trim())
    .filter(h => h);

  const separators = separatorLine
    .split('|')
    .map(s => s.trim())
    .filter(s => s);

  // Validate that the separator line contains only dashes or colons
  const isValidSeparator = separators.every(sep => /^:?-+:?$/.test(sep));
  if (!isValidSeparator) return null;

  // Parse the rows
  const dataRows = rows.map(row =>
    row
      .split('|')
      .map(cell => cell.trim())
      .filter(cell => cell)
  );

  return (
    <div className="overflow-x-auto my-4">
      <table className="min-w-full divide-y divide-gray-700">
        <thead className="bg-gray-800">
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className="px-4 py-2 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
              >
                {renderMarkdownText(header)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-gray-900 divide-y divide-gray-700">
          {dataRows.map((row, rowIndex) => (
            <tr key={rowIndex} className="hover:bg-gray-800">
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className="px-4 py-2 text-sm text-gray-300">
                  {renderMarkdownText(cell)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function processContent(content: string): React.ReactNode[] {
  const elements: React.ReactNode[] = [];

  // Updated regex to match line, pie, and bar chart code blocks
  const rechartRegex =
    /```(rechart-(line|pie|bar)-chart)\s*\n([\s\S]*?)\n```/g;

  let lastIndex = 0;
  let match: RegExpExecArray | null;

  // Process chart code blocks first
  while ((match = rechartRegex.exec(content)) !== null) {
    const matchIndex = match.index;

    // Get the text before the code block
    const beforeCode = content.slice(lastIndex, matchIndex);
    if (beforeCode) {
      // Process markdown tables in the text before the code block
      elements.push(...processTextWithTables(beforeCode));
    }

    // Get the chart type and code content
    const chartType = match[2]; // 'line', 'pie', or 'bar'
    const codeContent = match[3];

    // Parse the JSON content
    let chartData;

    try {
      chartData = JSON.parse(codeContent);

      // Now, create the appropriate chart component
      if (chartType === 'line') {
        elements.push(
          <React.Fragment key={`chart-${matchIndex}`}>
            <ChartComponent data={chartData} />
          </React.Fragment>
        );
      } else if (chartType === 'pie') {
        elements.push(
          <React.Fragment key={`chart-${matchIndex}`}>
            <PieChartComponent data={chartData} />
          </React.Fragment>
        );
      } else if (chartType === 'bar') {
        elements.push(
          <React.Fragment key={`chart-${matchIndex}`}>
            <BarChartComponent data={chartData} />
          </React.Fragment>
        );
      }
    } catch (error) {
      console.error('Failed to parse chart data:', error);
      elements.push(
        <span className="text-red-500" key={`error-${matchIndex}`}>
          Invalid chart data
        </span>
      );
    }

    // Update lastIndex
    lastIndex = rechartRegex.lastIndex;
  }

  // Process any remaining text after the last code block
  const afterCode = content.slice(lastIndex);
  if (afterCode) {
    elements.push(...processTextWithTables(afterCode));
  }

  return elements;
}

// New function to detect and render Markdown headings
function renderLine(line: string, key: string): React.ReactNode {
  // Check for Markdown headings (# Heading 1, ## Heading 2, etc.)
  const headingMatch = line.match(/^(#{1,6})\s+(.+)$/);
  
  if (headingMatch) {
    const level = headingMatch[1].length; // Number of # characters
    const headingText = headingMatch[2].trim();
    
    // Return the appropriate heading element
    switch (level) {
      case 1:
        return <h1 key={key} className="text-white text-4xl font-bold mt-4 mb-2">{renderMarkdownText(headingText)}</h1>;
      case 2:
        return <h2 key={key} className="text-white text-3xl font-bold mt-3 mb-2">{renderMarkdownText(headingText)}</h2>;
      case 3:
        return <h3 key={key} className="text-white text-2xl font-bold mt-3 mb-1">{renderMarkdownText(headingText)}</h3>;
      case 4:
        return <h4 key={key} className="text-white text-xl font-bold mt-2 mb-1">{renderMarkdownText(headingText)}</h4>;
      case 5:
        return <h5 key={key} className="text-white text-lg font-bold mt-2 mb-1">{renderMarkdownText(headingText)}</h5>;
      case 6:
        return <h6 key={key} className="text-white text-base font-bold mt-2 mb-1">{renderMarkdownText(headingText)}</h6>;
      default:
        return <p key={key}>{line}<br /></p>;
    }
  }
  
  // For regular text, return as is
  return (
    <React.Fragment key={key}>
      {line}
      <br />
    </React.Fragment>
  );
}

// Updated helper function to process text and convert markdown tables
function processTextWithTables(text: string): React.ReactNode[] {
  const elements: React.ReactNode[] = [];
  const markdownTableRegex = /((?:^\|.*\n)+(?:^\|.*$)?)/gm;

  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = markdownTableRegex.exec(text)) !== null) {
    const matchIndex = match.index;

    // Get the text before the table
    const beforeTable = text.slice(lastIndex, matchIndex);
    if (beforeTable) {
      elements.push(
        ...beforeTable.split('\n').map((line: string, index: number) => 
          renderLine(line, `text-${matchIndex}-${index}`)
        )
      );
    }

    // Get the table text
    const tableText = match[1];

    // Parse and render the table
    const tableElement = parseMarkdownTable(tableText);
    if (tableElement) {
      elements.push(
        <React.Fragment key={`table-${matchIndex}`}>{tableElement}</React.Fragment>
      );
    } else {
      // If parsing fails, render the table text as is
      elements.push(
        ...tableText.split('\n').map((line: string, index: number) => 
          renderLine(line, `text-${matchIndex}-table-${index}`)
        )
      );
    }

    // Update lastIndex
    lastIndex = markdownTableRegex.lastIndex;
  }

  // Process any remaining text after the last table
  const afterTable = text.slice(lastIndex);
  if (afterTable) {
    elements.push(
      ...afterTable.split('\n').map((line: string, index: number) => 
        renderLine(line, `text-after-${lastIndex}-${index}`)
      )
    );
  }

  return elements;
}

interface TextToHTMLProps {
  content: string;
}

const TextToHTML: React.FC<TextToHTMLProps> = ({ content }) => {
  // Add effect to log raw content when component renders or content changes
  useEffect(() => {
    console.log('TextToHTML Raw Content:', content);
  }, [content]);

  return (
    <div className="prose max-w-none overflow-hidden">
      <Linkify
        options={{
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
      >
        {typeof content === 'string' ? (
          processContent(content)
        ) : (
          (() => {
            console.error(
              `Invalid content type: ${typeof content}. Content:`,
              content
            );
            return (
              <span className="text-red-500">
                Invalid content (Type: {typeof content})
              </span>
            );
          })()
        )}
      </Linkify>
    </div>
  );
};

export default TextToHTML;
