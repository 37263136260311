import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import { getPlannedWorkouts } from "../services/planned-workouts.service";
import { PlannedWorkoutsResponse } from "../models/planned-workouts-response";
import { Link } from "react-router-dom";

export const CalendarPage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [calendarData, setCalendarData] = useState<PlannedWorkoutsResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCalendar = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await getPlannedWorkouts(token);
        if (response.error) {
          setError(response.error.message);
        } else {
            console.log(calendarData);
            
          setCalendarData(response);
        }
      } catch (e: any) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };
    fetchCalendar();
  }, [getAccessTokenSilently]);

  if (loading) {
    return (
      <PageLayout>
        <div className="content-layout">
          <div className="content__body">
            <p className="text-center text-gray-600">Loading...</p>
          </div>
        </div>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <div className="content-layout">
          <div className="content__body">
            <p className="text-center text-red-500">Error: {error}</p>
          </div>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div className="content-layout">
        <div className="content__body p-4">
          <h1 className="text-slate-900 text-6xl tracking-tight font-extrabold sm:text-6xl dark:text-white mb-10">
            Training Calendar
          </h1>
          {/* Explanatory Note */}
          <div className="mb-8">
            <p className="text-l text-gray-400">
              To change the planned workouts, just{" "}
              <Link to="/chat" className="underline">
                ask your AI coach!
              </Link>
            </p>
          </div>
          {calendarData?.data?.days.map((day, i) => (
            <div key={i} className="mb-6">
              <h2 className="text-slate-900 text-4xl tracking-tight font-extrabold sm:text-4xl dark:text-white mb-4">
                {day.date}
              </h2>
              {day.workouts.map((workout) => (
                <div key={workout.id} className="ml-4">
                  <p className="text-gray-300 font-medium ">
                    {workout.description} ({workout.duration / 60} min)
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </PageLayout>
  );
};
