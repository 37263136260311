import { TrainingCalendar, PlannedWorkoutsResponse } from "../models/planned-workouts-response";
import { StrongifierChatMessage } from "../models/strongifier-chat-message";
import { fetchEventSource, EventSourceMessage } from "@microsoft/fetch-event-source";
import { ChatHistoryApiResponse } from "../models/chat-history-response";
import { ChatApiResponse } from "../models/chat-api-response";
import { callExternalApi } from "./external-api.service";
import { AxiosRequestConfig } from "axios";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getPlannedWorkouts = async (
  accessToken: string
): Promise<PlannedWorkoutsResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/planned-workouts`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    return {
      data: null,
      error,
    };
  }

  let parsedData: TrainingCalendar | null = null;

  //As a quick hack before rewiring how we do API calls, we will change the type of data to that of an object 
  // with a property of plannedWorkouts that is a string. This will allow us to parse the data as a TrainingCalendar.
  const typedData = data as unknown as { plannedWorkouts: string };

  if (typedData && typeof typedData.plannedWorkouts === "string") {
    try {
      parsedData = JSON.parse(typedData.plannedWorkouts) as TrainingCalendar;
    } catch (parseError) {
      return {
        data: null,
        error: { message: "Failed to parse response data" },
      };
    }
  } else {
    parsedData = data as unknown as TrainingCalendar;
  }

  return {
    data: parsedData,
    error: null,
  };
};